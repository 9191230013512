import $ from 'jquery';

export const api_url = "https://api.thrivequiz2050.com/index.php/";

export function runSlideshow() {
    setTimeout(function() {
        var cur = $('#home-slideshow .active');
        cur.removeClass('prepped');
        var next = cur.next();

        if (next.length === 0) {
            next = $('#home-slideshow .slide-image').first();
        }

        cur.addClass('former');
        next.addClass('prepped active');

    }, 3000);
    setTimeout(function() {
        $('#home-slideshow .former').removeClass('active former');
        window.home_slide = runSlideshow();
    }, 6000);
}