import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from "react-router-dom";
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
/*import Select from 'react-select';*/

import PropTypes from 'prop-types';
import gtmParts from 'react-google-tag-manager';
import detectBrowserLanguage from 'detect-browser-language';

import './scss/main.scss';
import $ from 'jquery';

import * as serviceWorker from './serviceWorker';
import * as mp from './mp';

import 'babel-polyfill';
require('es6-promise').polyfill();
require('isomorphic-fetch');

var rootMount = document.getElementById("montgomeryPlanning");
var quiz_id;
var referrer = '';
// if we want to store referrers or various url share properties this is where to set it

if(!localStorage.getItem('userdata') || window.location.search.indexOf('roaming') > -1)
{
    localStorage.setItem('userdata', Date.now() + Math.random());
}

if (typeof Object.assign !== 'function') {
  Object.assign = function(target) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source !== null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

// tag manager
class GoogleTagManager extends React.Component {
    static propTypes = {
        gtmId: PropTypes.string.isRequired,
        dataLayerName: PropTypes.string,
        additionalEvents: PropTypes.object,
        previewVariables: PropTypes.string,
        scriptId: PropTypes.string
    };

    componentDidMount() {
        const dataLayerName = this.props.dataLayerName || 'dataLayer';
        const scriptId = this.props.scriptId || 'react-google-tag-manager-gtm';
 
        if (!window[dataLayerName]) {
            const gtmScriptNode = document.getElementById(scriptId);
 
            eval(gtmScriptNode.textContent);
        }
    }
 
    render() {
        const gtm = gtmParts({
            id: this.props.gtmId,
            dataLayerName: this.props.dataLayerName || 'dataLayer',
            additionalEvents: this.props.additionalEvents || {},
            previewVariables: this.props.previewVariables || false,
        });
 
        return (
            <div>
                <div>{gtm.noScriptAsReact()}</div>
                <div id={this.props.scriptId || 'react-google-tag-manager-gtm'}>
                    {gtm.scriptAsReact()}
                </div>
            </div>
        );
    }
}
 
export default GoogleTagManager;


/////////////////////////
// VIEWS
/////////////////////////

class Landing extends React.Component {

    componentDidMount(){
        clearTimeout(window.home_slide);
        $('.slide-image').removeClass('active prepped');
        $('.slide-image').first().addClass('active');

        function preloadImages(srcs, imgs, callback) {
            var img;
            var remaining = srcs.length;
            for (var i = 0; i < srcs.length; i++) {
                img = new Image();
                img.onload = function() {
                    --remaining;
                    if (remaining <= 0) {
                        callback();
                    }
                };
                img.src = srcs[i];
                imgs.push(img);
            }
        }

        function showHome(){
            $('.center-wrapper').addClass('ready');
            $('#home-slideshow').addClass('active');
            $('.landing-page').addClass('loaded');
            window.home_slide = mp.runSlideshow();
        }

        var res = null;
        function resize(){
            res = setTimeout(function(){
                $('#home-slideshow, .slide-image, .landing-page, .home-shadow').css('height', $('.center-wrapper').outerHeight() + 'px');
                $('.center-block').css('max-height', $('.center-wrapper').outerHeight() + 'px');
            }, 500);
        }

        var images = [];
        var imageSrcs = [];

        fetch(mp.api_url + "wp-json/system_data/v1/home/", {
            method: 'get',
            headers: {
                "content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        })
        .then(res => res.json())
        .then(
            (result) => {

                quiz_id = result.quiz.id;
                localStorage.setItem('quiz_id', result.quiz.id);

                $('#home-title').text(result.quiz.title);
                $('#home-copy').html(result.quiz.body);
                $('#start-quiz').text(result.quiz.start_button_text);
                $('#home-disclaimer').html(result.quiz.start_explanation);

                var image;
                for(var i = 0; i < result.quiz.images.length; i ++)
                {
                    image = $('<div>').addClass('slide-image').attr('style', 'background:url(' + result.quiz.images[i].url + ') no-repeat center center; background-size:cover;');

                    if(i === 0)
                    {
                        image.addClass('active');
                    }

                    $('#home-slideshow').append(image);
                    imageSrcs.push(result.quiz.images[i].url);
                }

                resize();

                preloadImages(imageSrcs, images, showHome);
            },
            (error) => {
                //console.log('failed')
            }
        )

        $(window).resize(resize);
    }

    render() {
        return (
            <div className="landing-page">
                <div className="center-wrapper">
                    <div className="center-block">
                        <div className="temp-holder">
                            <div id="home-logo"></div>
                            <h1 id="home-title"></h1>
                            <div id="home-copy"></div>
                            <Link to={{pathname: '/quiz/' + window.location.search}} className="fuzzy-button" id="start-quiz">Start Quiz</Link>
                            <div id="home-disclaimer"></div>
                        </div>
                    </div>
                </div>
                <div id="home-slideshow"></div>
                <div className="home-shadow"></div>
            </div>
        );
    }
}

class Quiz extends React.Component {

    constructor(props){
        super(props);

        const images = require.context('../public/images', true);

        this.qd = {
            answered: [],
            cur:1,
            total:0,
            quiz_id: 0,
            referrer: '',
            questions: [
            ],
            init: function(){
                // fetch data and load first question
                this.cur = 0;
                // this.total = this.questions.length;
                this.total = 10;

                this.setBG();

                $('#cur').text(this.cur + 1);
                $('#total').text(this.total);
                $('#quiz-text').html(this.questions[this.cur].Question);
                $('#fader').removeClass('final');
                setTimeout(function(){
                    $('.quiz-box').addClass('open');
                }, 1000);
                setTimeout(function(){
                    $('.answer-wrapper').addClass('open');
                }, 1200);

                //$('#user-id').html('User ID: ' + localStorage.getItem('userdata')).addClass('active');
            },
            setBG: function(){
                let imgsrc = this.questions[this.cur].BG_Image;

                $('.img-standard').css('background-image', 'url(' + imgsrc + ')');

                var styles = $('<style>').html('#post-answer:before, .quiz-box:before{background-image:url(' + imgsrc + ') !important}');

                $('#style-overrides').html(styles);
            },
            answerQuestion: function(value){
                $('.answer-wrapper').removeClass('open');

                this.answered.push([this.questions[this.cur].ID, this.questions[this.cur].Question.replace(/,/g, '___'), value]);

                $('.quiz-answer').slideUp();
                $('.quiz-next').slideDown();

                $('#post-answer').removeClass('yes no').addClass(value).css('top', ($('#quiz-text').outerHeight() + 40) + 'px');
                    
                var yesPercent = this.questions[this.cur].Stats.Yes - 1;
                var noPercent = 100 - yesPercent;
                var threshold = 30;

                if(window.matchMedia('(min-width: 600px)').matches){
                    threshold = 10;
                }

                if(value === 'yes'){
                    $('.post-yes span').text('You and ' + this.questions[this.cur].Stats.Yes + '% of people answered YES');
                    $('.post-no span').text(this.questions[this.cur].Stats.No + '% of people answered No');
                }
                else
                {
                    $('.post-yes span').text(this.questions[this.cur].Stats.Yes + '% of people answered YES');
                    $('.post-no span').text('You and ' + this.questions[this.cur].Stats.No + '% of people answered No');
                }
                if(noPercent >= threshold && yesPercent >= threshold) {
                    $('.post-yes').css({width: yesPercent + '%'});
                    $('.post-no').css({width: noPercent + '%'});
                }

                if(noPercent >= threshold && yesPercent <= threshold) {
                    $('.post-yes').css({display: 'none'});
                    $('.post-no').css({width: '100%'});
                }else{
                    $('.post-yes').css({display: 'table'});
                }

                if(yesPercent >= threshold && noPercent <= threshold) {
                    $('.post-yes').css({width: '100%'});
                    $('.post-no').css({display: 'none'});
                }else{
                    $('.post-no').css({display: 'table'});
                }

                if(yesPercent <= 20) {
                    $('.post-yes').addClass('narrow');
                }else {
                    $('.post-yes').removeClass('narrow');
                }

                if(noPercent <= 20) {
                    $('.post-no').addClass('narrow');
                }else {
                    $('.post-no').removeClass('narrow');
                }

                let imgsrc = this.questions[this.cur].Animation;

                setTimeout(function(){
                    $('#post-answer').slideDown();
                }, 500);

                setTimeout(function(){
                    $('#gif-container').css('background-image', 'url(' + imgsrc + '?v=' + Math.floor(Math.random() * Math.floor(100)) + ')').show();
                }, 1000);

                setTimeout(function(){
                    $('.answer-wrapper').addClass('open');
                }, 3000);


            },
            next: function(){
                $('#gif-container').fadeOut();
                $('.quiz-next').slideUp();
                $('.answer-wrapper').removeClass('open');
                $('#post-answer').slideUp();
                
                this.cur += 1;

                if(this.cur >= this.total)
                {

                    let imgsrc = this.questions[0].BG_Image;
                    $('#fader').css('background-image', 'url(' + imgsrc + ')');
                    var text = "What's Your Zip?";
                    text += '<div id="zip-message"></div><input type="text" id="zip" name="zip" placeholder="Zip Code">';
                    text += '<div id="zip-buttons"><button id="zip-skip">Skip</button><button id="zip-submit">Submit</button></div>';

                    setTimeout(function(){
                        $('.quiz-box').removeClass('open');
                        $('.answer-wrapper').hide();
                    }, 500);

                    setTimeout(function(){
                        $('#fader').fadeIn();
                    }, 750);

                    setTimeout(function(){
                        $('.quiz-answer').slideDown();
                    }, 1000);

                    setTimeout(function(){
                        $('.img-standard').css('background-image', 'url(' + imgsrc + ')');

                        var styles = $('<style>').html('#post-answer:before, .quiz-box:before{background-image:url(' + imgsrc + ') !important}');
                        $('#style-overrides').html(styles);
                    }, 1250);

                    setTimeout(function(){
                        $('.quiz-box').addClass('open');
                        $('#quiz-text').addClass('final').html(text);
                        $('#fader').fadeOut();

                        var curscope = this;

                        $('#zip-skip').click(function(){
                            $('#finalize').click();
                        });

                        $('#zip-submit').click(function(){
                            var zip = $('#zip').val();
                            if(zip !== '')
                            {
                                $('#finalize').click();
                            }
                            else
                            {
                                $('#zip-message').text('Please enter your zip code.').slideDown(500).delay(3000).slideUp(500);
                            }
                        });

                    }, 1500);    

                }
                else
                {
                    let imgsrc = this.questions[this.cur].BG_Image;
                    $('#fader').css('background-image', 'url(' + imgsrc + ')');
                    var text = this.questions[this.cur].Question;
                    var cur = this.cur + 1;


                    setTimeout(function(){
                        $('.quiz-box').removeClass('open');
                        $('.answer-wrapper').addClass('open');
                    }, 500);

                    setTimeout(function(){
                        $('#fader').fadeIn();
                    }, 750);

                    setTimeout(function(){
                        $('.quiz-answer').slideDown();
                    }, 1000);

                    setTimeout(function(){
                        $('.img-standard').css('background-image', 'url(' + imgsrc + ')');

                        var styles = $('<style>').html('#post-answer:before, .quiz-box:before{background-image:url(' + imgsrc + ') !important}');
                        $('#style-overrides').html(styles);
                    }, 1250);

                    setTimeout(function(){
                        $('.quiz-box').addClass('open');
                        $('#quiz-text').html(text);
                        $('#cur').text(cur);
                        $('#fader').fadeOut();
                    }, 1500);                    
                }

            },
            finishQuiz: function(){
                var zip = $('#zip').val();

                $('#gif-container').fadeOut();
                $('.quiz-next').slideUp();
                $('.answer-wrapper').removeClass('open');
                $('#post-answer').slideUp();
                $('#quiz-footer').fadeOut();
                $('.quiz-box').removeClass('open');
                $('#fader').css('background', '#ffffff').addClass('final').fadeIn();

                var message = '';
                var bg = false;

                //we send results to the server
                fetch(mp.api_url + "wp-json/system_data/v1/log/", {
                    method: 'post',
                    headers: {
                        "content-type": "application/x-www-form-urlencoded; charset=UTF-8"
                    },
                    body: "quiz_id=" + this.quiz_id + "&answers=" + JSON.stringify(this.answered) + "&quiz_user=" + localStorage.getItem('userdata') + "&referrer_data=" + this.referrer + "&zip=" + zip + "&language=" + detectBrowserLanguage()
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        var title = result.category.title;
                        var sub = result.category.subtitle;
                        var copy = result.category.copy;
                        bg = result.category.bg_image;
                        var anim = result.category.bg_animation;

                        var fb = $('.social-icon.facebook').data('final').replace(/%5Bresult%5D/g, result.category.title);
                        var tw = $('.social-icon.twitter').data('final').replace(/%5Bresult%5D/g, result.category.title)
                        var em = $('.social-icon.mail').data('final').replace(/%5Bresult%5D/g, result.category.title)
                        var ems = $('.social-icon.mail').data('fsubject');

                        $('.social-icon.facebook').attr('href', 'https://www.facebook.com/sharer/sharer.php?u=https://thrivequiz2050.com&t=' + fb);
                        $('.social-icon.twitter').attr('href', 'https://twitter.com/share?url=https://thrivequiz2050.com&via=montgomeryplans&text=' + tw);
                        $('.social-icon.mail').attr('href', 'mailto:MyFriendInMontgomeryCounty@email.com?subject=' + ems + '&body=' + em);

                        var share_buttons = $('#social-buttons').html();

                        message = '<h1>' + title + '</h1>' + (sub !== '' ? '<h2>' + result.category.subtitle + '</h2>' : '') + '<p>' + result.category.copy + '</p><div id="signup-wrapper">';

                        message += '<div class="share-wrapper"><h3>Share this Quiz</h3>' + share_buttons + '</div>';

                        message += '<div id="quiz-signup"><h3>Sign Up Here To Receive Updates From Montgomery Planning</h3><div id="signup-message"></div><input type="text" id="signup-email" name="signup-email" placeholder="Email Address"><button id="signup-button">Sign Up</button></div>';

                        message += '<div class="sitelink">Discover more about your path and learn about <a href="https://montgomeryplanning.org/planning/master-plan-list/general-plans/thrive-montgomery-2050/our-future-your-path/" target="_blank">Thrive Montgomery 2050.</a></div>';

                        document.addEventListener('click', function (event) {

                            // If the clicked element doesn't have the right selector, bail
                            if (event.target.matches("#signup-button")) {

                                console.log('the target is on');


                                var em = $('#signup-email').val();
                                if(em === '')
                                {
                                    $('#signup-message').text('Please enter a valid email address.').slideDown(500).delay(3000).slideUp(500);
                                }
                                else
                                {
                                    var changer = $('#quiz-signup');
                                    fetch(mp.api_url + "wp-json/system_data/v1/signup/", {
                                        method: 'post',
                                        headers: {
                                            "content-type": "application/x-www-form-urlencoded; charset=UTF-8"
                                        },
                                        body: "signup_email=" + em
                                    })
                                    .then(res => res.json())
                                    .then(
                                        (result) => {
                                            $(changer).slideUp(500, function(){
                                                $(changer).html('<span class="thank-you">Thank you for signing up!<span>').slideDown();
                                            });
                                        },
                                        (error) => {
                                           $(changer).slideUp(500, function(){
                                                $(changer).html("Something went wrong. We're sorry.").slideDown();
                                            });
                                        }
                                    )
                                }  
                            }                         

                        }, false);

                        function finalReveal(){
                            setTimeout(function(){
                                $('.img-standard').css('background-image', 'url(' + bg + ')');

                                var styles = $('<style>').html('#post-answer:before, .quiz-box:before{background-image:url(' + bg + ') !important}');
                                $('#style-overrides').html(styles);

                                $('#quiz-text').html(message);
                                $('#fader').fadeOut();

                                $('.quiz-box').addClass('open final');
                                //$('#quiz-footer').fadeOut();
                                $('.survey').addClass('released');  
                            }, 1000);

                            setTimeout(function(){
                                $('#gif-container').css('background-image', 'url(/images/confetti.gif?v=' + Math.floor(Math.random() * Math.floor(100)) + ')').show();
                            }, 1500);
                          
                        }

                        var img = new Image();
                        img.addEventListener('load', finalReveal, false);
                        img.src = bg;

                    },
                    (error) => {
                        message = 'There was a problem storing your quiz results.';

                        $('#quiz-text').html(message);
                    }
                )
            },
            reset: function(){
                this.cur = 0;
                this.total = 0;
                this.answered = [];
                $('#cur').text(this.cur + 1);
                $('#total').text(this.total);
            }
        };
    }

    componentDidMount(){
        const detectBrowserLanguage = require('detect-browser-language');

        fetch(mp.api_url + "wp-json/system_data/v1/quiz/", {
            method: 'post',
            headers: {
                "content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "quiz_id=" + localStorage.getItem('quiz_id') + "&quiz_user=" + localStorage.getItem('userdata') + "&referrer_data=" + this.referrer
        })
        .then(res => res.json())
        .then(
            (result) => {

                this.qd.quiz_id = result.quiz_id;
                this.qd.referrer = ''; // eventually read a uri segment or something
                this.qd.questions = result.questions;

                this.qd.init();

                $('#question-submit').click(function(e){
                    e.preventDefault();

                    var question = $("#question-copy").val();
                    var zip = $('#question-zip').val();
                    var errors = [];

                    $('#question-response').removeClass('error');

                    if(question === ''){
                        errors.push('Please enter a question you would like to see in the survey.');
                    }

                    if(zip === ''){
                        errors.push('Please enter your zip code.');
                    }

                    if(errors.length > 0)
                    {
                        $('#question-response').html(errors.join('<br />')).addClass('error').slideDown().delay(3000).slideUp();
                    }
                    else
                    {
                        fetch(mp.api_url + "wp-json/system_data/v1/question/", {
                            method: 'post',
                            headers: {
                                "content-type": "application/x-www-form-urlencoded; charset=UTF-8"
                            },
                            body: "quiz_id=" + localStorage.getItem('quiz_id') + "&quiz_user=" + localStorage.getItem('userdata') + "&question=" + question + "&zip=" + zip
                        })
                        .then(res => res.json())
                        .then(
                            (result) => {
                                if(result.success)
                                {
                                    $('#question-form').slideUp();
                                    $('#question-response').html(result.message).addClass('success').slideDown();
                                    $('#add-another').slideDown();
                                }
                                else
                                {
                                    $('#question-response').html(result.message).addClass('error').slideDown().delay(3000).slideUp();
                                }
                            },
                            (error) => {
                                //console.log('failed')
                            }
                        )
                    }

                });

                $('#add-another').click(function(){
                    $("#question-copy").val('');
                    $("#question-zip").val('');
                    $('#add-another').slideUp();
                    $('#question-response').slideUp();
                    $('#question-form').slideDown();
                });

                $('#question-modal .modal-copy').html(result.ask_question_copy);
                $('#share-modal .modal-copy').html(result.share_copy);
                $('#accessibility-modal .modal-copy').html(result.accessibility_copy);

                $('.social-icon.facebook').attr('href', 'https://www.facebook.com/sharer/sharer.php?u=https://thrivequiz2050.com&t=' + encodeURIComponent(result.intro_share_facebook_title)).data('final', encodeURIComponent(result.share_facebook_title));
                $('.social-icon.twitter').attr('href', 'https://twitter.com/share?url=https://thrivequiz2050.com&via=montgomeryplans&text=' + encodeURIComponent(result.intro_share_twitter_copy)).data('final', encodeURIComponent(result.share_twitter_copy));
                $('.social-icon.mail').attr('href', 'mailto:MyFriendInMontgomeryCounty@email.com?subject=' + result.intro_share_email_subject + '&body=' + result.intro_share_email_copy).data('fsubject', result.share_email_subject ).data('final', encodeURIComponent(result.share_email_copy));

            },
            (error) => {
                //console.log('failed')
            }
        )
    }

    openModal(type){
        $('#modal').fadeIn();
        setTimeout(function(){
            $('#' + type + '-modal').fadeIn();

            if(type === 'question')
            {
                $('#question-form').slideDown();
                $('#question-response').hide();
            }
        }, 500);
    }

    closeModal(){
        $('#modal').fadeOut();
        $('.modal-content').fadeOut();
    }

    openTwitterModal(){
        window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
        return false;
    }

    render() {
        return (
            <div className="survey img-standard">
                <header id="quiz-header">
                    <div className="suggest" onClick={() => this.openModal('question')}><span className="question-icon"></span> Suggest A Question</div>
                    <a href={`/` + window.location.search} className="thrive-logo">Thrive</a>
                    <div className="share" onClick={() => this.openModal('share')}><span className="share-text">Share This <span className="share-quiz">Quiz</span></span> <span className="share-icon"></span> </div>
                    <div className="shadow"></div>
                </header>
                <div className="center-wrapper">
                    <div className="center-block">
                        <div className="quiz-wrapper">
                            <div className="quiz-box img-before">
                                <div id="quiz-text"></div>
                                <div className="answer-wrapper">
                                    <span id="quiz-yes" className="quiz-answer yes" onClick={() => this.qd.answerQuestion('yes')}>Yes</span> <span id="quiz-no" className="quiz-answer" onClick={() => this.qd.answerQuestion('no')}>No</span>
                                    <div className="quiz-next" onClick={() => this.qd.next()}><span>Next</span></div>
                                </div>
                            </div>
                            <div id="post-answer" className="img-before">
                                <div className="post-yes"><span></span></div>
                                <div className="post-no"><span></span></div>
                            </div>
                        </div>
                    </div>
                    <div id="finalize" onClick={() => this.qd.finishQuiz()}></div>
                </div>
                <footer id="quiz-footer">
                    <div id="accessibility-button" onClick={() => this.openModal('accessibility')}>
                        <span className="accessibility-icon"></span>
                        <div className="accessibility-text">Accessibility <br />&amp; Translation</div>
                    </div>
                    <div id="quiz-counter"><div className="counter-content">Question <span id="cur">1</span>/<span id="total">10</span></div></div>
                </footer>
                <div id="fader"></div>
                <div id="gif-container"></div>
                <div id="style-overrides"></div>
                <div id="modal">
                    <div id="accessibility-modal" className="modal-content">
                        <div className="modal-copy">Accessibility</div>
                        <div className="modal-close" onClick={() => this.closeModal()}>Close</div>
                    </div>
                    <div id="share-modal" className="modal-content">
                        <h2>Share The Quiz</h2>
                        <div className="modal-copy"></div>
                        <div id="social-buttons" className="share-wrapper">
                            <a href="https://www.facebook.com/sharer/sharer.php?u=https://thrivequiz2050.com&amp;t=planning+quiz" className="social-icon facebook" target="_blank" rel="noopener noreferrer" title="Share on Facebook">Share on Facebook</a>
                            <a href="https://twitter.com/share?url=https://thrivequiz2050.com&amp;via=montgomeryplanning&amp;text=planning+quiz" onClick={() => this.openTwitterModal()} target="_blank" rel="noopener noreferrer" title="Share on Twitter" className="social-icon twitter">Share on Twitter</a>
                            <a href="mailto:MyFriendInMontgomeryCounty@email.com?subject=Take+This+Quiz&body=https://thrivequiz2050.com" title="Share via Email" className="social-icon mail">Share via Email</a> 
                        </div>
                        <div className="modal-close" onClick={() => this.closeModal()}>Close</div>
                    </div>
                    <div id="question-modal" className="modal-content">
                        <div className="modal-copy">Question</div>
                        <div className="question-form">
                            <form action="" method="post" id="question-form">
                                <fieldset>
                                    <div className="form-row">
                                        <label htmlFor="question-copy">Your Suggested Question</label>
                                        <textarea id="question-copy" placeholder="Your suggested question"></textarea>
                                    </div>
                                    <div className="form-row">
                                        <label htmlFor="question-zip">Zip Code</label>
                                        <input type="text" id="question-zip" name="question-zip" placeholder="Your Zipcode" />
                                    </div>
                                    <div className="form-row">
                                        <button className="question-submit" id="question-submit">Submit</button>
                                    </div>
                                </fieldset>
                            </form>
                            <div id="question-response"></div>
                            <div id="add-another">Suggest Another Qestion</div>
                        </div>
                        <div className="modal-close" onClick={() => this.closeModal()}>Close</div>
                    </div>
                </div>
                <div id="user-id"></div>
            </div>
        );
    }
}

/////////////////////////
// URL ROUTING
/////////////////////////
const Main = () => (
    <main>
        <Switch>
            <Route exact path='/' component={Landing}/>
            <Route path='/quiz' component={Quiz}/>
        </Switch>
    </main>
);

/////////////////////////
// FULL DASH CLASS
/////////////////////////

class Dash extends React.Component {

    render() {
        return (
            <div className="mp-survey">
                <GoogleTagManager gtmId='GTM-5G75BTL' />
                <Main />
            </div>
        );
    }
}

ReactDOM.render((

    <BrowserRouter>
        <Dash />
    </BrowserRouter>

), rootMount);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
